/* globals define:true */
define('Startect/section/a-proven-record',["vue", "../graph-plotter", "../scrollWindow", "json!Startect/section/proven-in-australia.json", "text!../templates/a-proven-record-graph.tpl.html"],
    function (Vue, plotter, scrollWindow, templateData, tpl) {
        "use strict";

        var PIXELS = 400,

            $el,

            scrollTop,

            onTabHandler;

        function initialize() {

            bindEvents();

            $(function() {
                $(document.querySelector(".js-tab-button [data-map-overlay]")).trigger("click");
                scrollTop = scrollWindow(document.querySelector(".js-container"));
            });

            plotter.init($el, $el.find(".js-pins-wrapper").first(), "solid-science"); 

            addAxisValue();
        }

        function bindEvents() {

            $el.on("click", ".js-tab-button li", onTabHandler.bind(this));
            $el.on("click", "[data-link], .js-swipe-back", renderInnerPane.bind(this));
        }

        onTabHandler = function (e) {
            var active,
                $active,
                activeOverlay;

            return function (e) {
                e.preventDefault();

                var mapOverlay = e.currentTarget.getAttribute("data-map-overlay");

                if (active) {
                    $active.removeClass("active");

                    [].forEach.call($el[0].querySelectorAll(".overlay--" + activeOverlay), function (item) {
                        item.style.display = "none";
                    })
                }

                active = e.currentTarget;
                $active = $(active);
                $active.addClass("active");

                [].forEach.call($el[0].querySelectorAll(".overlay--" + mapOverlay), function (item) {
                    item.style.display = "block";
                });
                activeOverlay = mapOverlay;

            };
        }();

        function addAxisValue() {
            // tmp *arr
            var arr, ratio, current, data, tallyItem, tallyCounter;

            Object.keys(templateData).map(function (item) {

                arr = templateData[item];
                arr.efficacity.ppRatio = 400 / (arr.efficacity.max - arr.efficacity.min);

                ratio = arr.efficacity.ppRatio;

                current = 0;

                data = [];
                arr.efficacity.data = data;

                tallyCounter = 0;
                // let's do the maths once and for all
                for (tallyItem in arr.tally) {
                    tallyCounter++;
					if(typeof arr.tally[tallyItem] != "string") {
                    	arr.tally[tallyItem] = ((arr.tally[tallyItem] >= 99.9) ? ">99.9" : Math.round(arr.tally[tallyItem] * 10) / 10);
					}
                }

                arr.tally.width = Math.round(100/--tallyCounter * 100)/100;

                while (arr.efficacity.min + current <= arr.efficacity.max) {
                    data.push({position: 34 + ratio * current, value: arr.efficacity.min + current});
                    current += arr.efficacity.step;
                }
            });
        }

        function renderInnerPane(e) {

            // To optimise and make sure we're getting the best out of a
            // two way data binding.
            //if(this.vue) {
            //
            //    Object.keys(templateData[link]).map(function(item) {
            //        console.info(item);
            //    })
            //
            //} else {

            var link = e.currentTarget.getAttribute("data-link");

            scrollTop();
            plotter.click(link);

            var vue = templateData[link];
            new Vue({
                el: $el[0].querySelector('.js-container'),
                template: tpl,
                data: vue
            });
            //}
        }

        return function (node) {

            $el = $(node);
            initialize();

        }.bind(this);
    }
);
