
define("json!Startect/section/guidelines.json", function(){ return {
    "NNQ": {
        "label": "NTH NSW & QLD",
        "results": {
            "prelambing": [
                "<strong>Fixed treatment with short acting drench</strong>",
                "<span class='align-center'>–</span>"
            ],
            "marking": [
                "<strong>WEC and drench if required*</strong> <br/> (Rotate with prelambing drench)",
                "<strong>WEC and drench if required*</strong> <br/> (Rotate with prelambing drench)"
            ],
            "weaning": [
                "<strong>WEC and drench if required*</strong>",
                "<strong>Fixed treatment</strong>"
            ],
            "summer": [
                "<strong>WECs every 4 weeks and drench if required</strong>",
                "<strong>WECs every 4 weeks and drench if required</strong>"
            ],
            "autumn": [
                {
                    "label":"<strong>WEC and drench if required</strong> <br/><strong>WECs every 6 weeks over winter and drench if required*</strong>",
                    "body":"Use short acting effective drench as primer and moxidectin LA (not to be done annually, but only if high risk of barber’s pole)"
                },
                "<strong>WEC every 6 weeks over winter and drench if required*</strong>"

            ]
        },
        "advice": {
            "title": "Use Startect as a routine short-acting drench in your <span class=\"highlight-type-1\">worm control program</span>",
            "expert": "Associate Professor Lewis Kahn",
            "list": [
                "Preparing low worm-risk spring lambing paddocks may reduce the need for the use of a long-acting drench at pre-lambing, or the use of a drench at marking. Paddocks can be prepared by ensuring that during the preceding March-April they are either spelled, grazed with cattle, or grazed with sheep for a maximum of 3 weeks after an effective drench has been used.",
                "It is important to know which drenches work on your farm by regularly conducting worm egg count reduction tests (a DrenchTest) every 2-3 years.",
                "Use effective drenches, where possible a combination of 2 or more actives and rotate among all effective drench groups each time a mob is drenched.",
                "Startect can be used every time an effective short-acting drench is required.",
                "Refer to the Drench Decision Guides on the WormBoss website (<a href=\"http://www.wormboss.com.au\" target=\"_blank\">www.wormboss.com.au</a>) to determine whether a drench is required."
            ],
            "footnote":"<p class='footnote'>* Based on Drench Decision Guide (available from <a href=\"http://www.wormboss.com.au\" target=\"_blank\">WormBoss website</a>)</p>"
        }
    },
    "SNSTVSA": {
        "label": "STH NSW/ STH Tablelands/ VIC/ SE SA",
        "results": {
            "prelambing": [
                "<span class=\"cell-no-results\">WEC and BCS (ideal is >2)</span>",
                "<span class='align-center'>–</span>"
            ],
            "marking": [
                "<span class='align-center'>–</span>",
                "<span class='align-center'>–</span>"
            ],
            "weaning": [
                "<strong>WEC and drench if required</strong>",
                "<strong>Fixed treatment with a known fully effective drench</strong> (if resistance status is unknown, use a new active)"
            ],
            "summer": [
                {
                    "label":"<strong>WEC and drench if required</strong>",
                    "body":"Rotate drenches between first and second summer drench. \r\n If only one summer drench is used, leave until Dec/Jan"
                },
                {
                    "label":"<strong>WEC and drench if required</strong>",
                    "body":"Rotate drenches between first and second summer drench. \r\n If only one summer drench is used, leave until Dec/Jan"
                }
            ],
            "autumn": [
                "WEC and drench if required",
                {
                    "label":"<strong>WEC every 4–6 weeks starting 2 weeks after autumn break through to summer. <br /><br/>Drench if required.</strong>",
                    "body":"More frequent drenching may be required if in high rainfall (>650 mm) areas"
                }
            ]
        },
        "advice": {
            "title": "Use Startect as a routine short-acting drench in your <span class=\"highlight-type-1\">worm control program</span>",
            "expert": "Dr John Larsen, Dr David Hucker, Dr Colin Trengove",
            "list": [
                "Ensure safe paddocks are prepared for weaners either by spelling these paddocks, grazing with cattle, or grazing with sheep for a maximum of 3 weeks after an effective drench has been used, or by using a “Smart Graze” program.  Select safe paddocks for use at lambing, particularly for maiden ewes, older ewes or early lambing ewes.",
                "It is important to know which drenches work on your farm by regularly conducting worm egg count reduction tests (a DrenchTest) every 2-3 years.",
                "Use effective drenches, where possible a combination of 2 or more actives and rotate among all effective drench groups each time a mob is drenched.",
                "Startect can be used any time  an effective short-acting drench is required, such as either of the summer drenches or at weaning.",
                "In wool flocks, depending on the time of lambing and or egg counts, the weaning drench in ewes will usually coincide with the first summer drench. In prime lamb flocks this can be more variable, depending upon the timing of lambing (usually 2-6 weeks earlier than for wool flocks) and weaning (variable, depending upon seasonal conditions) so an additional drench may be required.",
                "During a wet summer, regular WECs are recommended to determine if a second summer drench is required (or occasionally even a third may be needed for weaners in ‘high risk’ areas when egg counts are high).",
                "During a dry summer, only a the first summer drench may be necessary.  Low WECs at monitoring in February could indicate no need for a second drench.",
                "Quarantine drench all introduced sheep using at least three different actives.",
                "Refer to the Drench Decision Guide on the WormBoss website (<a href=\"http://www.wormboss.com.au\" target=\"_blank\">www.wormboss.com.au)</a> to determine whether a drench is required depending on the season and the WEC result."
            ]
        }
    },
    "CN": {
        "label": "Central NSW",
        "results": {
            "prelambing": [
                "WEC and drench if indicated",
                "<span class='align-center'>–</span>"
            ],
            "marking": [
                "<em>May/June lambing:</em> <br/><strong>WECs over winter – drench with Startect or any other drench if above 200 epg</strong> <br/><br/> <em>Spring/Autumn lambing:</em> <br/><strong>Drench (if no pre-lambing drench given)</strong>",
                "<span class='align-center'>–</span>"
            ],
            "weaning": [
                "<em>May/June lambing:</em> <br/><strong>Drench</strong>  (coincides with 1st summer drench)<br/> <br/><em>Spring/Autumn lambing:</em> <br/> <strong>Drench </strong>(if no pre-lambing drench given)",
                "<em>May/June lambing:</em> <br/><strong>Drench</strong><br/> <br/><em>Spring/Autumn lambing:</em> <br/> <strong>Drench; continue monitoring</strong>"
            ],
            "summer": [
                "<em>May/June lambing:</em> <br/><strong>WEC and first summer drench</strong> <br/> <br/><em>Spring/Autumn lambing:</em> <br/><strong>WEC and first summer drench; WEC prior to 2nd summer drench </strong>(at weaning of spring lambs)",
                "<span class='align-center'>–</span>"
            ],
            "autumn": [
                "<span class='align-center'>–</span>",
                "<span class='align-center'>–</span>"
            ]
        },
        "advice": {
            "title": "Use Startect as a routine short-acting drench in your <span class=\"highlight-type-1\">worm control program</span>",
            "expert": "Dr Rob Woodgate",
            "list": [
                "Ensure safe paddocks are prepared for weaners either by spelling these paddocks, grazing with cattle, or grazing with sheep for a maximum of 3 weeks after an effective drench has been used, or by using a “Smart Graze” program.  Select safe paddocks for use at lambing, particularly for maiden ewes, older ewes or early lambing ewes.",
                "It is important to know which drenches work on your farm by regularly conducting worm egg count reduction tests (a DrenchTest) every 2-3 years.",
                "Use effective drenches, where possible a combination of 2 or more actives and rotate among all effective drench groups each time a mob is drenched.",
                "Startect can be used any time an effective short-acting drench is required, such as either of the summer drenches or at weaning.",
                "In wool flocks, depending on the time of lambing and or egg counts, the weaning drench in ewes will usually coincide with the first summer drench. In prime lamb flocks this can be more variable, depending upon the timing of lambing (usually 2-6 weeks earlier than for wool flocks) and weaning (variable, depending upon seasonal conditions) so an additional drench may be required.",
                "During a wet summer, regular WECs are recommended to determine if a second summer drench is required (or occasionally even a third may be needed for weaners in ‘high risk’ areas when egg counts are high).",
                "During a dry summer, only a the first summer drench may be necessary.  Low WECs at monitoring in February could indicate no need for a second drench.",
                "Quarantine drench all introduced sheep using at least three different actives.",
                "Refer to the Drench Decision Guide on the WormBoss website (<a href=\"http://www.wormboss.com.au\" target=\"_blank\">www.wormboss.com.au)</a> to determine whether a drench is required depending on the season and the WEC result."
            ]
        }
    },
    "TSC": {
        "label": "TAS/ STH Vic",
        "results": {
            "prelambing": [
                "<strong>Drench</strong>",
                "<span class='align-center'>–</span>"
            ],
            "marking": [
                "<span class='align-center'>–</span>",
                "<span class='align-center'>–</span>"
            ],
            "weaning": [
                "<strong>Drench if on perennial pastures</strong>",
                "<strong>Drench</strong>"
            ],
            "summer": [
                {
                    "label": "<strong>Drench if on perennial pastures</strong> (usually coincides with weaning)",
                    "body": "In Merino/ Merino X enterprises, ewes may require a second summer drench based on WEC"
                },
                "WEC every 4–6 weeks and drench based on results"
            ],
            "autumn": [
                "<span class='align-center'>–</span>",
                {
                    "label": "<strong>WEC every 4-6 weeks and drench based on results</strong> <br/><br/>",
                    "body": "Quarantine drench if introducing prime lambs onto another property for finishing"
                }
            ]
        },
        "advice": {
            "title": "Use Startect as a routine short-acting drench in your <span class=\"highlight-type-1\">worm control program</span>",
            "expert": "Dr Paul Nilon, Dr John Larsen",
            "list": [
                "Use grazing management, such as a “Smart Graze” program to prepare safe paddocks for weaning.",
                "It is important to know which drenches work on your farm by regularly conducting worm egg count reduction tests (a DrenchTest) every 2-3 years.",
                "Use effective drenches, where possible a combination of 2 or more actives and rotate among all effective drench groups each time a mob is drenched",
                "Startect can be used any time an effective short-acting drench is required, such as weaning.",
                "Depending on the timing of lambing, the weaning drench in the ewes may coincide with the first summer drench.  Ewes remaining on perennial pastures should receive a weaning/first summer drench",
                "In low rainfall regions, give the first summer drench at lamb marking time. In the high rainfall and summer rainfall regions, give the first summer drench at weaning time.",
                "During a wet summer, regular WECs are recommended to determine if a second summer drench is required.",
                "During a dry summer, only a first summer drench may be necessary.  Monitor using WECs.",
                "Quarantine drench all introduced sheep using at least four different actives.",
                "Refer to the Drench Decision Guides on the WormBoss website (<a href=\"http://www.wormboss.com.au\" target=\"_blank\">www.wormboss.com.au</a>) to determine whether a drench is required depending on the season and the WEC result."
            ]
        }
    },
    "WASA": {
        "label": "WA/Cereal zones of SA",
        "results": {
            "prelambing": [
                "WEC in ewes due to lamb mid-June or later  <br /><br/>Drench if required",
                "<span class='align-center'>–</span>"
            ],
            "marking": [
                "<span class='align-center'>–</span>",
                "<span class='align-center'>–</span>"
            ],
            "weaning": [
                "<span class='align-center'>–</span>",
                "<strong>Drench</strong>"
            ],
            "summer": [
                "<span class='align-center'>–</span>",
                "<strong>Drench with fully effective drench</strong>"
            ],
            "autumn": [
                "<strong>Drench adults in April</strong> (unless they were given a summer drench last year) – also acts as a pre-lambing drench in early lambing systems",
                "<strong>WEC on hoggets every 6 weeks from late May to late October <br/>  <br/> Drench if required on WEC  or in presence of scouring"
            ]
        },
        "advice": {
            "title": "Use Startect as a routine short-acting drench in your <span class=\"highlight-type-1\">worm control program</span>",
            "expert": "Dr Brown Besier, Dr Colin Trengove",
            "list": [
                "Ensure safe paddocks are prepared for weaners either spelling these paddocks, cropping, grazing with cattle, or grazing with sheep with an egg count of less than 200 epg.  Select safe paddocks for use at lambing, particularly for maiden ewes, twin-bearing ewes., or ewes in poorer condition.",
                "It is important to know which drenches work on your farm by regularly conducting worm egg count reduction tests (a DrenchTest) every 2-3 years.",
                "Use effective drenches, where possible a combination of 2 or more actives and rotate among all effective drench groups each time a mob is drenched.",
                "Startect can be used any time an effective short-acting drench is required, such as weaning, the first summer drench in weaners and hoggets, or any time a drench is indicated following a high WEC.",
                "To delay resistance, it is important to maintain refugia (population of susceptible worms). It is recommended to not give the ewes a first summer drench.  Instead, drench the ewes in autumn, which will also coincide with a pre-lambing drench on some properties",
                "In low rainfall areas (<350mm annually), ewes may not require a drench, although WEC tests are advised in wet years.",
                "Quarantine drench all introduced sheep using at least four different actives.",
                "Refer to the Drench Decision Guides on the WormBoss website (<a href=\"http://www.wormboss.com.au\" target=\"_blank\">www.wormboss.com.au</a>) ) to determine whether a drench is required depending on the season and the WEC result."            ]
        }
    }
};});

