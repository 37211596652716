/*global define */

define('Startect/flipbox',["jquery"], function($) {
	"use strict";
	/**
	 * Module Description
	 *
	 * @returns {ModuleExample}
	 * @constructor
	 */
	function FlipBox() {
        bindEvents();
	}

	/**
	 * Click handler
	 *
	 * @param  Object  e Event Object
	 */
	function flipBox(e) {
        $(e.currentTarget).toggleClass('active');
	}

    function bindEvents() {
        $(".js-panel-inner").on("click", $.proxy(flipBox, this));
    }
	return FlipBox;
});
