/* globals define:true */
define(
    'Startect/section/guidelines',["handlebars", "../graph-plotter", "../scrollWindow", "text!Startect/section/table.tpl.html", "json!Startect/section/guidelines.json", "text!Startect/section/popover.tpl.html", "bootstrap-popover"],
    function (Handlebars, plotter, scrollWindow, tpl, dataJSON, popOverBaseTpl) {

        "use strict";

        var $content,
            scrollTop,
            template = Handlebars.compile(tpl),
            popOverTpl = Handlebars.compile(popOverBaseTpl);

        // init
        $(function () {
            if (document.querySelector(".container-guidelines")) {
                bindEvents();

                scrollTop = scrollWindow($content[0].querySelector(".js-template-container"));
                plotter.init($('[data-link-target="guidelines"]'), $(".js-pins-wrapper").first(), "guidelines");
            }
        });

        Handlebars.registerHelper('noop', function (element, options) {
            if (typeof element === "object") {
                return popOverTpl(element) + element['label'];
            }
            return element;
        });

        Handlebars.registerHelper('isEmptyCell', function(element) {

            if(isCellTypeFour(element)) {
                return "cell-type-4";
            } else {
                return "";
            }
        });

        function isCellTypeFour(element) {
            return typeof element === "object" ||
                (
                    typeof element === "string" &&
                    (
                        element.indexOf("align-center") === -1 &&
                        element.indexOf("cell-no-results") === -1
                    )
                )
        }

        function bindEvents() {

            $content = $(".site-content").first();

            $content.on('click', '.nav-tabs a', function (e) {
                e.preventDefault();

                $(this).tab('show');
            });

            $content.on("click", "[data-link]", onSlideContentHandler.bind(this));
        }

        function render(id) {

            $("div.popover").remove();

            $content[0].querySelector(".js-template-container").innerHTML = template(dataJSON[id]);

            // forces the nav button to reset
            $content.find('.nav-tabs a').first().click();
            $content.find(".js-button-popover").popover({html: true});
        }

        function onSlideContentHandler(e) {
            var link;
            e.preventDefault();

            if (link = e.currentTarget.getAttribute("data-link")) {

                scrollTop();
                plotter.click(link);
                render.call(this, link);

                //swappableTitle.textContent = e.currentTarget.dataset.title;
                //$panelsWrapper.addClass("panel-transition-left");
            } else {
                //swappableTitle.textContent = decodeURIComponent(swappableTitleDefaultValue);
                //$panelsWrapper.removeClass("panel-transition-left");
            }
        }
    }
);
