
define("json!Startect/section/proven-in-australia.json", function(){ return {
    "dummy": {
        "label":"",
        "regionalTrials":"",
        "extraNotice":"",
        "keys":[],
        "viruses":[],
        "efficacity":{},
        "tally":{},
        "data":[]
    },
	"nothern-nsw": {
		"label": "NORTHERN NSW",
        "regionalTrials": "Northern NSW/ Southern QLD n=39",
		"keys": [
			{ "label": "STARTECT", "color": "record-color--1" },
			{ "label": "Moxidectin", "color": "record-color--2" },
			{ "label": "ML/BZ/Lev", "color": "record-color--3" },
			{ "label": "OP/BZ/Lev", "color": "record-color--4" }
		],
		"viruses": [
			"overall",
		    "Barber's Pole Worm",
			"Brown Stomach Worm",
			"Black Scour Worm"
		],
		"efficacity": {
			"min":0,
			"max":100,
			"step":10
		},
		"tally": {
			"overall": 98.98,
			"haemonchus": 97.94,
			"teladorsagia": 100,
			"trichostrongylus": 99.51
		},
		"data": [
            [98.98, 86.29, 88.62, 78.14],
            [97.94, 74.02, 78.5, 82.32],
            [100, 98.24, 100, 100],
            [99.51, 99.15, 99.84, 58.37]
		]
	},
    "central-nsw": {
        "label": "Central NSW",
        "regionalTrials": "Central NSW n=19",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Moxidectin", "color": "record-color--2" },
            { "label": "BZ", "color": "record-color--3" },
            { "label": "Levamisole", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Barber's Pole Worm",
            "Brown Stomach Worm",
            "Black Scour Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": 99.7,
            "haemonchus": 98.42,
            "teladorsagia": 100,
            "trichostrongylus": 100
        },
        "data": [
            [99.71, 91.53, 54, 90.75],
            [98.42, 82.27, 38, 99.72],
            [100 ,92.14, 72, 45.57],
            [100, 99.99, 99.96, 82.8]
        ]
    },
    "southern-nsw": {
        "label": "Southern NSW",
        "regionalTrials": "Southern NSW n=27",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Moxidectin", "color": "record-color--2" },
            { "label": "OP/BZ/Lev", "color": "record-color--3" },
            { "label": "Levamisole", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Barber's Pole Worm",
            "Brown Stomach Worm",
            "Black Scour Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": 99.97,
            "haemonchus": 99.75,
            "teladorsagia": 98.17,
            "trichostrongylus": 99.99
        },
        "data": [
            [99.97, 95.29, 96.64, 93.71],
            [99.75, 80.54, 100, 99.96],
            [98.17, 96.32, 93.81, 58.26],
            [99.99, 99.96, 98.64, 73.32]
        ]
    },
    "southern-slope": {
        "label": "Southern Slopes",
        "regionalTrials": "Southern Slopes n=17",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Ivermectin", "color": "record-color--2" },
            { "label": "BZ/Lev", "color": "record-color--3" },
            { "label": "IVM/BZ/Lev", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Barber's Pole Worm",
            "Brown Stomach Worm",
            "Black Scour Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": 99.9,
            "haemonchus": 99.75,
            "teladorsagia": 98.95,
            "trichostrongylus": 99.8
        },
        "data": [
            [99.9, 65.81, 93.34, 99.35],
            [99.75, 80.54, 100, 99.96],
            [98.95, 74.65, 71.1, 93.92],
            [99.8, 89.81, 94.77, 98.07]
        ]
    },
    "north-east-victoria": {
        "label": "North East Victoria",
        "regionalTrials": "North Eastern Victoria n=14",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Ivermectin", "color": "record-color--2" },
            { "label": "Moxidectin", "color": "record-color--3" },
            { "label": "OP/BZ/Lev", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Barber's Pole Worm",
            "Brown Stomach Worm",
            "Black Scour Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": 100,
            "haemonchus": 100,
            "teladorsagia": 100,
            "trichostrongylus": 100
        },
        "data": [
            [100, 78.04, 97.87, 98.51],
            [100, 40.38, 78.96, 99.85],
            [100, 80.22, 92.61, 88.76],
            [100, 89.26, 98.98, 96.1]
        ]
    },
    "western-districts-central-victoria": {
        "label": "Western Districts & Central Victoria",
        "regionalTrials": "Western Districts and Central Victoria n=25",
        "extraNotice":"*BZ/Lev was not tested against Barber’s Pole Worm",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Ivermectin", "color": "record-color--2" },
            { "label": "Moxidectin", "color": "record-color--3" },
            { "label": "BZ/Lev", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Barber's Pole Worm",
            "Brown Stomach Worm",
            "Black Scour Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": 99.99,
            "haemonchus": 100,
            "teladorsagia": 100,
            "trichostrongylus": 100
        },
        "data": [
            [99.99, 87.13, 99.05, 93.23],
            [100, 49.02, 78.14, 0],
            [100, 67.87, 91.02, 71.23],
            [100, 92.04, 99.77, 89.53]
        ]
    },
	"tasmania": {
		"label": "Tasmania",
        "regionalTrials": "Tasmania n=3",
		"keys": [
			{ "label": "STARTECT", "color": "record-color--1" },
			{ "label": "Ivermectin", "color": "record-color--2" },
			{ "label": "Levamisole", "color": "record-color--3" },
			{ "label": "BZ", "color": "record-color--4" }
		],
		"viruses": [
			"overall",
			"Brown Stomach Worm",
			"Black Scour Worm"
		],
		"efficacity": {
			"min":0,
			"max":100,
			"step":10
		},
		"tally": {
			"overall": 99.9,
			"teladorsagia": 99.9,
			"trichostrongylus": 99.29
		},
		"data": [
			[99.95, 87.65, 97.04, 89.98],
			[100, 35.82, 80.98, 71.23],
			[99.29, 94.4, 100, 98.17]
		]
	},
    "south-australia": {
        "label": "South Australia",
        "regionalTrials": "South Australia n=14",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Ivermectin", "color": "record-color--2" },
            { "label": "Moxidectin", "color": "record-color--3" },
            { "label": "ABA/BZ/ Lev", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Brown Stomach Worm",
            "Black Scour Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": '99.9',
            "teladorsagia": 98.94,
            "trichostrongylus": 99.9
        },
        "data": [
            [99.88, 94.93, 96.82, 99.41],
            [98.94, 77.76, 82.72, 97.16],
            [100, 91.49, 96.87, 99.25]
        ]
    },
    "great-southern-wa": {
        "label": "Great Southern WA",
        "regionalTrials": "Great Southern WA n=22",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Moxidectin", "color": "record-color--2" },
            { "label": "BZ/Lev", "color": "record-color--3" },
            { "label": "ML/BZ/Lev", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Brown Stomach Worm",
            "Black Scour Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": 99.67,
            "teladorsagia": 98.94,
            "trichostrongylus": 99.99
        },
        "data": [
            [99.67, 99.4, 91.78, 99.24],
            [98.94, 91.8, 90.45, 87.99],
            [99.99, 99.93, 85.72, 100]
        ]
    },
    "sheep-wheat-belt": {
        "label": "Sheep Wheat Belt",
        "regionalTrials": "Sheep Wheat Belt WA n=5",
        "keys": [
            { "label": "STARTECT", "color": "record-color--1" },
            { "label": "Ivermectin", "color": "record-color--2" },
            { "label": "Moxidectin", "color": "record-color--3" },
            { "label": "BZ/Lev", "color": "record-color--4" }
        ],
        "viruses": [
            "overall",
            "Brown Stomach Worm",
            "Black Scour Worm",
            "Barber's Pole Worm"
        ],
        "efficacity": {
            "min":0,
            "max":100,
            "step":10
        },
        "tally": {
            "overall": 100,
            "teladorsagia": 100,
            "trichostrongylus": 100,
            "haemonchus": 100
        },
        "data": [
            [100, 88.37, 98.38, 95.22],
            [100, 78.15, 100, 93.75],
            [100, 98.03, 100, 78.85],
            [100, 95.05, 100, 100]
        ]
    }
};});

