define('Startect/startect',[ 'jquery',
		'Startect/flipbox',
        'Startect/section/a-proven-record',
        'Startect/section/guidelines'
	],
function($, FlipBox, provenRecord, guidelines) {
	'use strict';

	/**
	 *
	 * @constructor
	 */
	function Startect() {


            var DROPDOWN_ITEM = '.dropdown';
		/**
		 * Bind UI Events
		 */
		var bindEvents = function bindEvents(){
			$('.js-print').on('click', function(e){
				e.preventDefault();
				window.print();
			});

            $('.nav-tabs a').on('click', navTabs);
		};

		/**
		 * Print page
		 */
		var enablePlaceholders = function enablePlaceholders(){
			$('input, textarea').placeholder();
		};

        var isIE = function isIE() {
            if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || !!navigator.userAgent.match(/Trident.*rv[ :]*11\./)) {
                $('html').addClass('ie');
            }
        };

        var dropdownMenu = function dropdownMenu() {
            $(DROPDOWN_ITEM).hover( function (e) {
                $(this).toggleClass('open', e.type === 'mouseenter');
            });
        };


        var navActiveStates = function navActiveStates() {
            var $navTarget = $('.site-content').find('[data-link-target]').data('link-target'),
                activeClass = 'active';

            $('.primary-navigation').find('[data-page-link=' + $navTarget + ']')
                .addClass(activeClass)
                .closest(DROPDOWN_ITEM)
                .find('.dropdown-toggle')
                .addClass(activeClass);
        };


        var navTabs = function navTabs(e) {

            e.preventDefault();

            $(e.currentTarget).tab('show');
        };

		/**
		 * Initialize the site
		 */
		this.init = function init() {
			console.log('init projectName');

            bindEvents();

            var provenInAustralia;
            if(provenInAustralia = document.querySelector('[data-section="proven-in-australia"]')) {

                provenRecord(provenInAustralia);
            }

			// Initiate placeholder polyfill
			enablePlaceholders();
            isIE();
            dropdownMenu();
            navActiveStates();
			//Instanciate modules
			this.flipbox = new FlipBox();

            try {

                _alignFooter();
            } catch(e) {
                e.stack; // should be printing this :)
            }
		};

		this.init();
	}

    // ZSW-158
    function _alignFooter() {
        var $footer = $("footer"),
            $content = $(".site-content"),
            $window = $(window);

        $window.on("resize", function() {

            if($content.offset().top + $content.height() + $footer.height() + 100< $window.height()) {
                if($footer[0].style.position !== "absolute") {

                    $footer.css({
                        position: "absolute",
                        bottom: 0,
                        width: '100%'
                    });
                }
            } else {
                $footer[0].style.position = "";
                $footer[0].style.bottom = "";
                $footer[0].style.width = "";
            }
        });
    }

	return Startect;

});

