
define("json!Startect/graph-plotter.json", function(){ return {
    "solid-science": {
        "sheep-wheat-belt": {
            "x":95,
            "y":156
        },
        "great-southern-wa": {
            "x":93,
            "y":234
        },
        "nothern-nsw": {
            "x":765,
            "y":147
        },
        "south-australia": {
            "x":515,
            "y":220
        },
        "north-east-victoria": {
            "x":673,
            "y":337
        },
        "southern-nsw": {
            "x":643,
            "y":255
        },
        "southern-slope": {
            "x":718,
            "y":289
        },
        "western-districts-central-victoria": {
            "x":603,
            "y":318
        },
        "central-nsw": {
            "x":698,
            "y":215
        },
        "tasmania": {
            "x":681,
            "y":457
        }
    },
    "guidelines": {
        "NNQ": {
            "x":442,
            "y":234
        },
        "WASA": {
            "x":59,
            "y":242
        },
        "SNSTVSA": {
            "x":327,
            "y":303
        },
        "TSC": {
            "x":374,
            "y":413
        },
        "CN": {
            "x":402,
            "y":272
        }
    }
};});

