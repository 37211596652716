define(
    'Startect/graph-plotter',[
        "text!./graph-plotter.tpl.html",
        "json!./graph-plotter.json"
    ],
    function(tpl, data) {
        var _$el,
            _$wrap,

            _prev,

            _nodes = {};

        // public methods
        var module = {

            init: function($el, $wrap, sectionName) {

                _$el = $el;
                _$wrap = $wrap;
                _createNodes(data[sectionName]);
            },

            click: function(id) {

                if(_prev) {
                    _prev.deactivate();
                }

                _prev = _nodes[id].activate();
            }
        };

        // private
        function _createNodes(data) {

            var item, node;
            for(item in data) {//_$el.find("[data-link=" + item + "]"),
                node = new Hoverable(data[item]);
                _nodes[item] = node;

                _$wrap.append(node.$el);
            }
        }

        function Hoverable(pos) {
            this.$el = $(tpl);
            this.$el.css({ left:pos.x, top:pos.y });
        }

        Hoverable.prototype.activate = function() {
            this.$el.addClass("active");
            return this;
        };

        Hoverable.prototype.deactivate = function() {
            this.$el.removeClass("active");
        };

        return module;
    }
);
